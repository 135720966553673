import React, { useContext, useRef } from "react";
import "./style.scss";
import Lupa from "../../../assets/img/lupa.png";
import Flag from "../../../assets/img/flag.png";
import Logo from "../../../assets/img/logos/HBlogotipo.svg";
// import Logo from "../../../assets/img/logos/HBlogotipo180.svg";
import Home from "../../../assets/img/home.svg";
import { Navbar, Container, Nav, NavDropdown, Modal } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import DrawerHB from "../drawer";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import axios from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import ReactGA from "react-ga4";
import IcoWP from "../../../assets/img/hb/Icon-awesome-whatsapp.png";
import NumeroAtencion from "../../../assets/img/hb/numero-atencion.png";
import { fetchExternal } from "../../../utils/fetch";
import LogoPlanSalud from "../../../assets/img/hb/logo-plan-salud-blanco.png";
import LogOutNavBar from "../../../assets/img/hb/Log out nav bar.svg";
import IconoCruz from "../../../assets/img/hb/Icono Cruz.svg";
import { InvitcionPSHBComponent } from "./modalPSHB";
import { useToggle } from "../../../utils/hooks/useToggle";

const NavBarHB = () => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [putSearch, setPutSearch] = React.useState(false);
  const visible = false;
  const onHide = () => null;
  const [info, setInfo] = React.useState(null);
  const [visibleMenu, setVisibleMenu] = React.useState(false);

  const { pathname } = useLocation();
  const invitacionToggle = useToggle();
  const [showMenu, setShowMenu] = React.useState(false);

  const dropdownRef = useRef(null);

  const accionLogo = () => {
    ReactGA.event({
      category: `Acciones-Home-HB`,
      action: "click",
    });
  };

  React.useEffect(() => {
    // const dniType = user.user.data.Customers.dniType;
    // if (dniType === "1" || dniType === "3") {
    //   informationToggle.onToggle();
    // }
    // getData();
    getInfo();
  }, []);

  const getInfo = async () => {
    const { data } = await fetchExternal(
      `${process.env.REACT_APP_STRAPI_QA}/pagina-principals?idEmpresa=1`,
      "GET"
    );
    setInfo(data[0]);
  };

  const accionHome = () => {
    ReactGA.event({
      category: `Acciones-Home-HB`,
      action: "click",
      label: "home",
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const formatName = () => {
    const userName = user.user.data.Customers.firstName
      .split(" ")[0]
      .toLowerCase();
    return userName.charAt(0).toUpperCase() + userName.slice(1);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="web-navbar">
        <div className="d-none d-lg-block">
          <div className="grandientNavbarHB">
            <div className="grandientNavbar_elements">
              <span className="text-center">
                <img
                  src={NumeroAtencion}
                  className="iconosAtencion"
                  alt="..."
                  style={{ fontSize: "25px" }}
                />{" "}
                Atención telefónica las 24hs: {info?.atencionTelefonica} /
                Central de turnos {info?.centralTurno} /{" "}
                <img
                  src={IcoWP}
                  className="iconosAtencion"
                  style={{ paddingBottom: "4px" }}
                />{" "}
                {info?.whatsapp}
              </span>
            </div>
          </div>
        </div>

        <div className="d-none d-lg-block">
          <Navbar className="customNavbar" expand="lg">
            <Navbar.Brand className="customNavbar_brand">
              <Nav.Link
                as={Link}
                to="/"
                style={{ height: "100%" }}
                className="p-0"
                onClick={() => accionLogo()}
              >
                <img
                  style={{
                    height: "65px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                  src={Logo}
                />
              </Nav.Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            {pathname !== "/cerrar-sesion" && (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {/* <Nav.Link as={Link} to="/grupo-familiar">
                    <span className="opcionMenu">Grupo familiar</span>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/perfil">
                    <span className="opcionMenu">Perfil</span>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/ayuda">
                    <span className="opcionMenu">Ayuda</span>
                  </Nav.Link> */}

                  <Nav.Link>
                    <span
                      onClick={() => {
                        setShowMenu(false);
                        if (user.user.data.Customers.pshb) {
                          setVisibleMenu(!visibleMenu);
                        } else {
                          invitacionToggle.onToggle();
                        }
                      }}
                      className="opcionMenuPlan"
                    >
                      Plan de Salud
                    </span>
                  </Nav.Link>

                  <Nav.Link ref={dropdownRef}>
                    <div
                      className="buttonUserName dropdown show"
                      onClick={() => {
                        setShowMenu(!showMenu);
                        setVisibleMenu(false);
                      }}
                      style={{ minWidth: "160px" }}
                    >
                      <div
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        id="button-info-user"
                      >
                        <span>Hola {formatName()}</span>
                        <i
                          className="bi bi-chevron-down ms-3"
                          style={{ fontSize: "10px" }}
                        ></i>
                      </div>

                      <div
                        className={`dropdown-menu-userButton ${
                          showMenu ? "show" : ""
                        }`}
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Nav.Link
                          className="dropdown-item"
                          as={Link}
                          to="/perfil"
                        >
                          <span className="opcionMenu-1">Perfil</span>
                        </Nav.Link>
                        <Nav.Link
                          className="dropdown-item"
                          as={Link}
                          to="/grupo-familiar"
                        >
                          <span className="opcionMenu-1">Grupo familiar</span>
                        </Nav.Link>
                        <Nav.Link
                          className="dropdown-item"
                          as={Link}
                          to="/ayuda"
                        >
                          <span className="opcionMenu-1">Ayuda</span>
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to="/cerrar-sesion"
                          id="cerrar-sesion"
                        >
                          Cerrar sesión <i class="bi bi-box-arrow-right"></i>
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Link>

                  {/* <Nav.Link as={Link} to="/cerrar-sesion">
                    <img
                      src={LogOutNavBar}
                      // className="icono-atencion"
                      style={{ height: "25px", paddingRight: "15px" }}
                      alt={"LogOut"}
                    />
                  </Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            )}
          </Navbar>

          {visibleMenu && (
            <div className="planSalud planSaludMenu">
              {user.user.data.Customers.pshb && (
                <>
                  <NavLink to={"/facturas"} activeclassname="active">
                    <span>Facturación</span>
                  </NavLink>
                  <NavLink to={"/reintegro-estado"} activeclassname="active">
                    <span>Reintegros</span>
                  </NavLink>
                  <NavLink to={"/credencial-digital"} activeclassname="active">
                    <span>Credencial</span>
                  </NavLink>
                </>
              )}

              <span onClick={() => setVisibleMenu(false)}>
                <img
                  src={IconoCruz}
                  // className="icono-atencion"
                  style={{ height: "18px", paddingRight: "5px" }}
                  alt={"LogOut"}
                />
              </span>
            </div>
          )}
        </div>

        <div className="d-block d-md-none">
          <Navbar className="" expand="sm">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="w-100">
              <Nav.Link
                as={Link}
                to="/"
                style={{ height: "100%" }}
                className="p-0"
              >
                <img src={Logo} />
              </Nav.Link>
            </Navbar.Toggle>

            <Navbar.Collapse id="basic-navbar-nav">
              <Navbar.Text>
                <div className="text-center m-3 saludoUsuarioHB">
                  ¡Hola {user.user.data.Customers.firstName}!
                </div>
              </Navbar.Text>

              <Nav className="itemMenuMovil">
                <Nav.Link>
                  <span className="opcionMenu">Grupo familiar</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/perfil">
                  <span className="opcionMenu">Perfil</span>
                </Nav.Link>
                <Nav.Link>
                  <span className="opcionMenu">Ayuda</span>
                </Nav.Link>
                <Nav.Link>
                  <span className="opcionMenuCerrarSesion">Cerrar sesión</span>
                </Nav.Link>
                <NavDropdown
                  title={
                    <img
                      src={LogoPlanSalud}
                      className="img-fluid"
                      alt="Logo plan salud"
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Mi plan</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Mi cartilla</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Facturación</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Reintegros</span>
                  </NavDropdown.Item>
                  <AiFillCloseCircle className="itemMenuC" onClick={onHide} />
                </NavDropdown>
                :<></>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </nav>

      <nav
        className="mobile-navbar"
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
      >
        <div className="d-flex">
          <div
            className="col-4 d-flex align-items-center"
            style={{ paddingLeft: "7vw" }}
          >
            <DrawerHB />
          </div>
          <div className="col-4 d-flex justify-content-center">
            <Nav.Link
              as={Link}
              to="/"
              style={{ height: "100%" }}
              className="p-0"
              onClick={() => accionLogo()}
            >
              <img
                style={{
                  height: "60px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                src={Logo}
              />
            </Nav.Link>

            {/* <Nav.Link
              as={Link}
              to="/"
              style={{ height: "100%" }}
              className="p-0"
            ></Nav.Link> */}
          </div>
          <div className="col-4"></div>
        </div>
        {/* <div className='d-flex justify-content-center align-items-center'>
                    <DrawerHB></DrawerHB>
                </div> */}
      </nav>
      <InvitcionPSHBComponent
        visible={invitacionToggle.toggle}
        onToggle={invitacionToggle.onToggle}
      />
    </>
  );
};

export default NavBarHB;
